export const STATUSES = {
  PENDING: "pending",
  ERROR: "rejected",
  SUCCESS: "resolved",
  VERIFIED: "verified",
};

function authReducer(state, action) {
  switch (action.type) {
    case "logout": {
      return {
        ...state,
        tokenStatus: "na",
        groupId: null,
      };
    }
    case "rejected": {
      return {
        ...state,
        tokenStatus: "rejected",
        error: action.error,
      };
    }
    case "missing-tokens": {
      return {
        ...state,
        tokenStatus: "na",
      };
    }
    case "pending": {
      return {
        ...state,
        tokenStatus: "pending",
      };
    }
    case "resolved": {
      return {
        ...state,
        tokenStatus: "resolved",
      };
    }
    case "otp-confirmed": {
      return {
        ...state,
        tokenStatus: "resolved",
        token: action.action.token,
        refreshToken: action.action.refreshToken,
      };
    }
    case "crossToken-confirmed": {
      if (state.tokenStatus === "verified") {
        return {
          ...state,
        };
      }
      return {
        ...state,
        tokenStatus: "resolved",
        token: action.action.token,
        refreshToken: action.action.refreshToken,
      };
    }
    case "verified": {
      return {
        ...state,
        tokenStatus: "verified",
        groupId: action.action.groupId,
        userScope: action.action.userScope,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export default authReducer;
