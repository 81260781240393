const crypto = require("crypto");
const moment = require("moment");
import * as fs from "fs";
const axios = require("axios");
const jwt = require("jsonwebtoken");

// specify the environment
// sod      => development
// qastage  => stage
// online   => production
const env = process.env.NEXT_PUBLIC_SUPEROFFICE_ENV;

// Partner Application Token (AKA Client Secret)
const appToken = process.env.NEXT_PUBLIC_SUPEROFFICE_CLIENT_SECRET;

// Your Online Sandbox Customer Identifier
const contextId = process.env.NEXT_PUBLIC_SUPEROFFICE_CONTEXT_ID;

// SystemUserToken provided as a claim in the callback (Redirect URL)
// when a tenant administrator successfully signs into SuperID.
const systemToken = process.env.NEXT_PUBLIC_SUPEROFFICE_SYSTEM_USER_TOKEN;

// Partners private key
const privKeyFile = "privatekey.pem";

// SuperOffice public key (SOD)
// Open SuperOfficeFederatedLogin.crt in notepad,
// save contents as SuperOfficeFederatedLogin.pem
const publKeyFile = "SuperOfficeFederatedLogin.pem";

export const getSystemUserTicket = async () => {
  try {
    const privateKeyFile =
      process.env.NEXT_PUBLIC_SUPEROFFICE_PRIVATE_KEY.replace(/\\n/g, "\n");

    // const publicKeyFile = fs.readFileSync(
    //   process.cwd() + `/${publKeyFile}`,
    //   "utf8"
    // );
    const publicKeyFile =
      process.env.NEXT_PUBLIC_SUPEROFFICE_PUBLIC_KEY.replace(/\\n/g, "\n");

    // prepare the datetime stamp
    const utcTimestamp = moment.utc().format("YYYYMMDDHHmm");
    const data = `${systemToken}.${utcTimestamp}`;

    log("Token.Time: " + data);

    // sign the System User token
    let sign = crypto.createSign("SHA256");
    sign.update(data);
    sign.end();
    sign = sign.sign(privateKeyFile, "base64");
    const signedToken = `${data}.${sign}`;

    log("Signed Token: " + signedToken);

    // send the request

    var postData = {
      SignedSystemToken: `${signedToken}`,
      ApplicationToken: `${appToken}`,
      ContextIdentifier: `${contextId}`,
      ReturnTokenType: "JWT",
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json;charset=UTF-8",
      },
    };

    const jwtRes = await axios.post(
      `https://${env}.superoffice.com/Login/api/PartnerSystemUser/Authenticate`,
      postData,
      axiosConfig
    );
    if (jwtRes.data.IsSuccessful) {
      var token = jwtRes.data.Token;

      var verifyOptions = {
        ignoreExpiration: true,
        algorithm: ["RS256"],
      };

      // validate the JWT and extract the claims
      var decoded = jwt.verify(token, publicKeyFile, verifyOptions);
      // write out the ticket to the console, DONE!
      const ticket = decoded["http://schemes.superoffice.net/identity/ticket"];
      return ticket;
    } else {
      log(
        "Getting the System User ticket was unsuccessful: " +
          jwtRes.data.ErrorMessage
      );
    }
  } catch (error) {
    log("Error: " + error);
  }
};

function log(message) {
  console.log("");
  console.log(message);
}

// Finally, execute the function to get the system user ticket!

// (async () => {
//   try {
//     const result = await getSystemUserTicket();
//     log("System User Ticket: " + result);
//   } catch (error) {
//     log(error);
//   }
// })();
