import * as Sentry from "@sentry/nextjs";
import { ApplicationAutoScaling } from "aws-sdk";
import axios from "axios";
import { decode } from "jsonwebtoken";
import Promise from "promise";
import React, { useContext, useEffect, useReducer, useState } from "react";
import fetchJson from "src/lib/fetchJson";
import { getSystemUserTicket } from "src/lib/superoffice";

import packageJson from "../../package.json";
import authReducer, { STATUSES } from "./authReducer";

const lmsApiKey = process.env.LMS_API_KEY;
const lmsApiBaseUrl = process.env.NEXT_PUBLIC_LMS_API_BASEURL;
const SOToken = process.env.NEXT_PUBLIC_SUPEROFFICE_CLIENT_SECRET;
const SOBaseUrl = process.env.NEXT_PUBLIC_SUPEROFFICE_BASE_URL;
const SOContextId = process.env.NEXT_PUBLIC_SUPEROFFICE_CONTEXT_ID;
const mapboxToken = process.env.NEXT_PUBLIC_MAPBOX_TOKEN;

const superofficeCategoryId = process.env.NEXT_PUBLIC_SUPEROFFICE_CATEGORY_ID;
const superofficeCategoryIdDeactivated =
  process.env.NEXT_PUBLIC_SUPEROFFICE_CATEGORY_ID_DEACTIVATED;
const superofficeBusinessId = process.env.NEXT_PUBLIC_SUPEROFFICE_BUSINESS_ID;
const superofficeCountryId = process.env.NEXT_PUBLIC_SUPEROFFICE_COUNTRY_ID;

const useAuth = () => {
  const login = async (loginToken) => {
    return fetchJson(`/api/auth/login`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({ idToken: loginToken }),
    });
  };

  const logout = async () => {
    return fetchJson("/api/auth/logout", { method: "POST" });
  };

  return {
    login,
    logout,
  };
};

const useMapbox = () => {
  const getCoordinates = async ({ address, zipcode, location }) => {
    const a = address.replace(" ", "%20");
    const z = zipcode.trim();
    const l = location.replace(" ", "%20");
    const response = await axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${a},${z}%20${l},%20Norway.json?access_token=${mapboxToken}`
      )
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    return response.data;
  };
  return { getCoordinates };
};

const useSuperOffice = () => {
  let SOTenantUrl = SOBaseUrl;

  const updateTenantUrl = async () => {
    const response = await axios.get(`${SOBaseUrl}/api/state/${SOContextId}`);
    const tenant = response.data;
    console.log({ tenant });
    SOTenantUrl = tenant.Endpoint;
    console.log({ SOTenantUrl });
  };

  const { getGroup } = useLMS();
  const testSO = async () => {
    const usertoken = await getSystemUserTicket();
    await updateTenantUrl();
    const response = await axios.get(`${SOTenantUrl}/api/v1/Contact/`, {
      headers: {
        Authorization: `SOTicket ${usertoken}`,
        "SO-AppToken": SOToken,
        Accept: "application/json",
      },
    });
    return response.data;
  };

  const getDefaultContact = async (usertoken) => {
    await updateTenantUrl();
    return axios
      .get(`${SOTenantUrl}/api/v1/Contact/default`, {
        headers: {
          Authorization: `SOTicket ${usertoken}`,
          "SO-AppToken": SOToken,
          Accept: "application/json",
        },
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
  };

  const getDefaultPerson = async (usertoken) => {
    await updateTenantUrl();
    return axios
      .get(`${SOTenantUrl}/api/v1/person/default`, {
        headers: {
          Authorization: `SOTicket ${usertoken}`,
          "SO-AppToken": SOToken,
          Accept: "application/json",
        },
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
  };

  const createSuperOfficeContact = async ({ knex, groupId }) => {
    // get relevant data from db
    let group = await knex
      .select(
        "groups.*",
        "users.email as userMail",
        "users.fullName as userName",
        "users.phone as userPhone",
        "users.id as userId"
      )
      .from("groups")
      .innerJoin("users", "users.groupId", "=", "groups.id")
      .where("groups.id", "=", groupId);
    let visitalAddress = await knex
      .select("addresses.*", "regions.name")
      .from("addresses")
      .innerJoin("regions", "regions.id", "=", "addresses.region")
      .where("addresses.groupId", "=", groupId)
      .andWhere("addresses.type", "=", "visit");
    let postalAddress = await knex
      .select("addresses.*", "regions.name")
      .from("addresses")
      .innerJoin("regions", "regions.id", "=", "addresses.region")
      .where("addresses.groupId", "=", groupId)
      .andWhere("addresses.type", "=", "postal");
    if (group.length === 0) {
      throw new Error("No groups with the given id");
    }
    const lmsGroup = await getGroup(group[0].lmsId);
    const kjede = await getGroup(lmsGroup.data[0]?.attributes?.parentGroupId);

    const usertoken = await getSystemUserTicket();
    let companyData = await getDefaultContact(usertoken);

    let company = {
      ...companyData.data,
      Name: `${group[0].name} ${group[0].orgnr}`,
      Phones: [
        {
          Value: group[0].phone,
          Description: "",
        },
      ],
      Emails: [
        {
          Value: group[0].email,
          Description: "",
        },
      ],
      Address: {
        ...companyData.data.Address,
        Street: {
          AtypeIdx: "Unknown",
          Address1: visitalAddress[0].address,
          Address2: "",
          Address3: "",
          City: visitalAddress[0].location,
          County: "Norge",
          State: "",
          Zipcode: visitalAddress[0].zipcode,
          Formatted: "",
        },
        Postal: {
          AtypeIdx: "Unknown",
          Address1: postalAddress[0].address,
          Address2: "",
          Address3: "",
          City: postalAddress[0].name,
          County: "Norge",
          State: "",
          Zipcode: postalAddress[0].zipcode,
          Formatted: "",
        },
      },
      Country: {
        CountryId: superofficeCountryId,
      },
      Business: {
        Id: superofficeBusinessId,
      }, // Should be "Treningssenter"
      Category: {
        Id: superofficeCategoryId,
      }, // Should be "kunde"
    };

    company.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_ORGNR] =
      group[0].orgnr; // "Org.nr"
    company.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_KJEDE] =
      kjede.data[0]?.attributes?.title; // "Kjede"
    company.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_VIRKE] =
      group[0].isMember; // "Vikrkemedlem"
    company.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_NHO] =
      group[0].nho; // "NHO Geneo"
    company.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_AVTALESTART] =
      new Date(); // "Avtale start"
    company.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_FYLKE] =
      visitalAddress[0].name; // "Fylke"
    company.CustomFields[
      process.env.NEXT_PUBLIC_SUPEROFFICE_SERTIFISERT
    ] = false; // "sertifisert" certified
    company.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_DATOSERTIFISERT] =
      undefined; // "Dato sertifisert" date certified
    company.CustomFields[
      process.env.NEXT_PUBLIC_SUPEROFFICE_RESERTIFISERT
    ] = false; // "resertifisert" recertified
    company.CustomFields[
      process.env.NEXT_PUBLIC_SUPEROFFICE_DATORESERTIFISERT
    ] = undefined; // "Dato resertifisert" date recertified
    company.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_CODE_MEMBER] =
      lmsGroup.data[0]?.attributes?.meta?.Medlemmer; // "Kode medlemmer" member code
    company.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_CODE_EMPLOYEE] =
      lmsGroup.data[0]?.attributes?.meta?.Ansatte; // "Kode ansatte" employee code
    company.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_KONTIGENT] =
      group[0].expenses; // "Kontigent"

    // create contact
    await updateTenantUrl();
    const contactResponse = await axios
      .post(
        `${SOTenantUrl}/api/v1/Contact`,
        {
          ...company,
        },
        {
          headers: {
            Authorization: `SOTicket ${usertoken}`,
            "SO-AppToken": SOToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    console.log(contactResponse.status);
    // update group superoffice id
    const updatedGroup = await knex("groups")
      .where("id", "=", groupId)
      .update({ superofficeId: contactResponse.data.ContactId });
    console.log(updatedGroup);

    // get default person
    let personData = await getDefaultPerson(usertoken);

    let person = {
      ...personData.data,
      Firstname: group[0].userName,
      MiddleName: "",
      Lastname: "",
      Title: "Antidoping ansvarlig",
      Emails: [
        {
          Value: group[0].userMail,
          Description: "Work Email",
        },
      ],
      MobilePhones: [
        {
          Value: group[0].userPhone,
          Description: "Work Phone",
        },
      ],
      Contact: {
        ContactId: contactResponse.data.ContactId,
      },
    };

    // create person and link with contact
    const personResponse = await axios
      .post(
        `${SOTenantUrl}/api/v1/person`,
        {
          ...person,
        },
        {
          headers: {
            Authorization: `SOTicket ${usertoken}`,
            "SO-AppToken": SOToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    console.log(personResponse.status);

    // update user superoffice id
    const updatedUser = await knex("users")
      .where("id", "=", group[0].userId)
      .update({ superofficeId: personResponse.data.PersonId });

    console.log(updatedUser);
  };

  const updateSuperOfficeContact = async ({
    knex,
    user,
    visitAddress,
    postalAddress,
    group,
    parentGroupId,
    groupId,
    newTitle,
  }) => {
    const usertoken = await getSystemUserTicket();
    if (Object.keys(group).length > 0) {
      const tempSOId = await knex
        .select("groups.superofficeId")
        .from("groups")
        .where("groups.id", "=", groupId);
      const groupSOId = tempSOId[0].superofficeId;

      let kjede = undefined;
      if (parentGroupId) {
        kjede = await getGroup(parentGroupId);
      }

      let changes = {
        Name: newTitle,
        Phones: group.phone
          ? [
              {
                Value: group.phone,
                Description: "",
              },
            ]
          : undefined,
        Emails: group.email
          ? [
              {
                Value: group.email,
                Description: "",
              },
            ]
          : undefined,
      };

      let visitRegion = undefined;
      let postalRegion = undefined;
      let visitChanges;

      if (Object.keys(visitAddress).length > 0) {
        if (visitAddress.region) {
          let tempvisit = await knex
            .select("name")
            .from("regions")
            .where("regions.id", "=", visitAddress.region);
          visitRegion = tempvisit[0].name;
        }
        visitChanges = {
          Address1: visitAddress.address ?? undefined,
          City: visitAddress.location ?? undefined,
          Zipcode: visitAddress.zipcode ?? undefined,
        };
      }
      let postalChanges = undefined;
      if (Object.keys(postalAddress).length > 0) {
        postalChanges = {
          AtypeIdx: "Unknown",
          Address1: postalAddress.address ?? undefined,
          City: postalAddress.location ?? undefined,
          Zipcode: postalAddress.zipcode ?? undefined,
        };
      }

      changes.Address = {
        Street: visitChanges,
        Postal: postalChanges,
      };
      console.log(changes);

      if (
        group.orgnr ||
        kjede ||
        typeof group.isMember !== "undefined" ||
        typeof group.nho !== "undefined"
      ) {
        changes.CustomFields = {};
        changes.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_ORGNR] =
          group.orgnr; // "Org.nr"
        changes.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_KJEDE] = kjede
          ? kjede.data[0]?.attributes?.title
          : undefined; // "Kjede"
        changes.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_VIRKE] =
          group.isMember; // "Vikrkemedlem"
        changes.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_NHO] =
          group.nho; // "NHO Geneo"
        changes.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_FYLKE] =
          visitRegion; // "Fylke"
      }

      await updateTenantUrl();
      // update contact
      const contactResponse = await axios
        .patch(
          `${SOTenantUrl}/api/v1/Contact/${groupSOId}`,
          {
            ...changes,
          },
          {
            headers: {
              Authorization: `SOTicket ${usertoken}`,
              "SO-AppToken": SOToken,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            Sentry.captureMessage(
              `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log(error);
            Sentry.captureException(error);
          }
        });
      console.log("Contact update: " + contactResponse.status);
    }

    const { id: userId, ...userData } = user;
    if (Object.keys(userData).length > 0) {
      const tempUserSOId = await knex
        .select("users.superofficeId")
        .from("users")
        .where("users.id", "=", userId);
      const userSOId = tempUserSOId[0].superofficeId;
      console.log("User superoffice id: " + userSOId);
      // person
      let personChanges = {
        Firstname: userData.fullName,
        Emails: userData.email
          ? [
              {
                Value: userData.email,
                Description: "Work Email",
              },
            ]
          : undefined,
        MobilePhones: userData.phone
          ? [
              {
                Value: userData.phone,
                Description: "Work Phone",
              },
            ]
          : undefined,
      };

      // create person and link with contact
      const personResponse = await axios
        .patch(
          `${SOTenantUrl}/api/v1/person/${userSOId}`,
          {
            ...personChanges,
          },
          {
            headers: {
              Authorization: `SOTicket ${usertoken}`,
              "SO-AppToken": SOToken,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            Sentry.captureMessage(
              `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log(error);
            Sentry.captureException(error);
          }
        });
      console.log("Person updated: " + personResponse.status);
    }
  };

  const updateSuperofficeCertification = async ({
    certified,
    certifiedDate,
    knex,
    groupId,
  }) => {
    const usertoken = await getSystemUserTicket();
    const tempSOId = await knex
      .select("groups.superofficeId")
      .from("groups")
      .where("groups.id", "=", groupId);
    const groupSOId = tempSOId[0].superofficeId;

    let changes = {
      CustomFields: {},
    };

    changes.CustomFields = {};
    changes.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_SERTIFISERT] =
      certified; // Org.nr
    changes.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_DATOSERTIFISERT] =
      certifiedDate; // Kjede

    await updateTenantUrl();
    // update contact
    const contactResponse = await axios
      .patch(
        `${SOTenantUrl}/api/v1/Contact/${groupSOId}`,
        {
          ...changes,
        },
        {
          headers: {
            Authorization: `SOTicket ${usertoken}`,
            "SO-AppToken": SOToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    console.log("Contact update: " + contactResponse.status);
  };

  const updateSuperofficeRecertification = async ({
    recertified,
    recertifiedDate,
    knex,
    groupId,
    certified,
  }) => {
    const usertoken = await getSystemUserTicket();
    const tempSOId = await knex
      .select("groups.superofficeId")
      .from("groups")
      .where("groups.id", "=", groupId);
    const groupSOId = tempSOId[0].superofficeId;
    let changes = {
      CustomFields: {},
    };

    changes.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_RESERTIFISERT] =
      recertified; // "Org.nr"
    changes.CustomFields[
      process.env.NEXT_PUBLIC_SUPEROFFICE_DATORESERTIFISERT
    ] = recertifiedDate ? recertifiedDate : undefined; // "Kjede"
    if (typeof certified !== "undefined") {
      changes.CustomFields[
        process.env.NEXT_PUBLIC_SUPEROFFICE_DATOSERTIFISERT
      ] = "";
      changes.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_SERTIFISERT] =
        certified;
    }

    await updateTenantUrl();
    // update contact
    const contactResponse = await axios
      .patch(
        `${SOTenantUrl}/api/v1/Contact/${groupSOId}`,
        {
          ...changes,
        },
        {
          headers: {
            Authorization: `SOTicket ${usertoken}`,
            "SO-AppToken": SOToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    console.log("Contact update: " + contactResponse.status);
  };

  const updateSuperofficeDeactivation = async ({ group }) => {
    const usertoken = await getSystemUserTicket();
    let changes = {
      Name: `${group.name} ${group.orgnr} DEAKTIVERT`,
      CustomFields: {},
      Category: {
        Id: superofficeCategoryIdDeactivated,
      },
    };

    changes.CustomFields[
      process.env.NEXT_PUBLIC_SUPEROFFICE_RESERTIFISERT
    ] = false; // "Org.nr"
    changes.CustomFields[
      process.env.NEXT_PUBLIC_SUPEROFFICE_DATORESERTIFISERT
    ] = ""; // "Kjede"
    changes.CustomFields[
      process.env.NEXT_PUBLIC_SUPEROFFICE_SERTIFISERT
    ] = false; // "Org.nr"
    changes.CustomFields[process.env.NEXT_PUBLIC_SUPEROFFICE_DATOSERTIFISERT] =
      ""; // "Kjede"
    await updateTenantUrl();
    // update contact
    const contactResponse = await axios
      .patch(
        `${SOTenantUrl}/api/v1/Contact/${group.superofficeId}`,
        {
          ...changes,
        },
        {
          headers: {
            Authorization: `SOTicket ${usertoken}`,
            "SO-AppToken": SOToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    console.log("Contact update: " + contactResponse.status);
  };

  const getSuperofficeBusinesses = async () => {
    const usertoken = await getSystemUserTicket();
    await updateTenantUrl();
    const response = await axios
      .get(`${SOTenantUrl}/api/v1/List/Business/Items`, {
        headers: {
          Authorization: `SOTicket ${usertoken}`,
          "SO-AppToken": SOToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    return response.data;
  };
  const getSuperofficeCountries = async () => {
    const usertoken = await getSystemUserTicket();
    await updateTenantUrl();
    const response = await axios
      .get(`${SOTenantUrl}/api/v1/List/Country/Items`, {
        headers: {
          Authorization: `SOTicket ${usertoken}`,
          "SO-AppToken": SOToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    return response.data;
  };
  const getSuperofficeCategories = async () => {
    const usertoken = await getSystemUserTicket();
    await updateTenantUrl();
    const response = await axios
      .get(`${SOTenantUrl}/api/v1/List/Category/Items`, {
        headers: {
          Authorization: `SOTicket ${usertoken}`,
          "SO-AppToken": SOToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    return response.data;
  };

  return {
    testSO,
    updateTenantUrl,
    createSuperOfficeContact,
    updateSuperOfficeContact,
    updateSuperofficeCertification,
    updateSuperofficeRecertification,
    updateSuperofficeDeactivation,
    getSuperofficeBusinesses,
    getSuperofficeCategories,
    getSuperofficeCountries,
  };
};

const useLMS = () => {
  const baseUrl = lmsApiBaseUrl;
  const lmsConfig = {
    headers: {
      Authorization: `Bearer ${lmsApiKey}`,
    },
  };
  const getGroup = async (id) => {
    const response = await axios.get(`${baseUrl}/groups/${id}`, lmsConfig);
    return response.data;
  };

  const getGroups = async () => {
    const response = await axios
      .get(`${baseUrl}/groups/`, lmsConfig)
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    return response.data;
  };

  const deleteGroup = async (id) => {
    const response = await axios.delete(`${baseUrl}/groups/${id}`, lmsConfig);
    return response.data;
  };

  const createGroup = async (data) => {
    const response = await axios
      .post(
        `${baseUrl}/groups/center`,
        {
          data,
        },
        lmsConfig
      )
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    return response.data;
  };

  const updateGroup = async (id, data) => {
    const response = await axios
      .patch(
        `${baseUrl}/groups/${id}`,
        {
          data: {
            type: "groups",
            id,
            attributes: {
              ...data,
            },
          },
        },
        lmsConfig
      )
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    console.log({ response });
    return response.data.data;
  };

  const getGroupUsersStatistics = async (id) => {
    const data = {
      learningpathObjectArray: [
        {
          customName: "Sti",
          learningpathIds: [],
          excludeLearningpathIds: [],
          status: "COMPLETE",
        },
      ],
      groupObjectArray: [
        {
          customName: "center",
          groupId: id,
          meta: [
            {
              role: ["center"],
            },
          ],
          withCondition: true,
        },
        {
          customName: "userrole",
          groupId: process.env.LMS_EMPLOYEE_ID,
          meta: [
            {
              role: ["user-role"],
            },
          ],
          withCondition: true,
        },
      ],
      userObject: {},
      searchObject: {},
    };

    const response = await axios
      .post(`${baseUrl}/statistics/users`, data, lmsConfig)
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Sentry.captureMessage(
            `data:${error.response.data}, status:${error.response.status}, headers:${error.response.headers}`
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(error);
          Sentry.captureException(error);
        }
      });
    return response.data;
  };

  return {
    getGroup,
    getGroups,
    createGroup,
    getGroupUsersStatistics,
    updateGroup,
    deleteGroup,
  };
};

export { useAuth, useLMS, useSuperOffice, useMapbox };
